.scrollable .account-card {
  margin: 10px;
  background: lighten($ui-base-color, 8%);
}

.scrollable .account-card__title__avatar {
  img,
  .account__avatar {
    border-color: lighten($ui-base-color, 8%);
  }
}

.scrollable .account-card__bio::after {
  background: linear-gradient(
    to left,
    lighten($ui-base-color, 8%),
    transparent
  );
}

.filter-form {
  background: $ui-base-color;

  &__column {
    padding: 10px 15px;
    padding-bottom: 0;
  }

  .radio-button {
    display: block;
  }
}

.radio-button {
  font-size: 14px;
  position: relative;
  display: inline-block;
  padding: 6px 0;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
  }

  &__input {
    display: inline-block;
    position: relative;
    border: 1px solid $ui-primary-color;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
    margin-right: 10px;
    top: -1px;
    border-radius: 50%;
    vertical-align: middle;

    &.checked {
      border-color: lighten($ui-highlight-color, 4%);
      background: lighten($ui-highlight-color, 4%);
    }
  }
}
